import { ipBasedLocation } from '@/config'
import { apiClient } from '@/services/ApiService'
import { Country } from '@/types'

export default {
  async unsupportedCountries(): Promise<Country[]> {
    return (await apiClient.get(`/data/unsupported-countries`)).data
  },

  async sitewideMessage() {
    const ipLocation = await ipBasedLocation()

    const country = ipLocation.country_code ? `/${ipLocation.country_code}` : ''
    return (await apiClient.get(`/data/sitewide-message${country}`)).data
  }
}
